import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastService} from './toast.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(public toastService: ToastService, public router: Router){

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    switch (event.status){
                      case 201: {
                        this.toastService.show((event.body?.message) ? event.body.message : 'Elemento inserito con successo', {class: 'bg-success text-light', delay: 3000});
                      }
                    }
                }
            }),
            catchError((error) => {
              this.toastService.show(error.error.message, {class: 'bg-danger text-light', delay: 5000});
              return throwError(error);
            })
            );
    }
}
